import { getCampuses, getDataUser, getRefreshToken } from 'Api';
import { AUTH_URL, REFRESH_TOKEN } from 'GlobalConstants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { getCampusesAction } from 'Redux/Reducers/initDataReducer';
import { PrivateRoute, PublicRoute } from './Layouts/';
import routes from './routes';
import { history } from './Utils';

function App() {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRefreshToken();
        localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.refreshToken));
      } catch {
        // window.location.href = AUTH_URL;
      }
      // Check permission
      const { data, status } = await getDataUser();
      localStorage.setItem('userId', JSON.stringify(data.id));
      if (
        (status === 200 && data.role === 'system') ||
        (status === 200 && data.role === 'manager' && data.chatHistoryAdmin)
      ) {
        setPermission(true);
      } else {
        setPermission(false);
      }
      // Get campuses
      const campuses = await getCampuses();
      dispatch(getCampusesAction(campuses.data));
    })();
  }, []);

  // useEffect(() => {
  //   fetchAllApi([
  //     getDataUser(),
  //     getCampuses(),
  //   ]).then(res => {
  //     const [{ data, status }, campuses] = res;
  //     localStorage.setItem('userId', JSON.stringify(data.id));
  //     if (
  //       (status === 200 && data.role === 'system') ||
  //       (status === 200 && data.role === 'manager' && data.chatHistoryAdmin)
  //     ) {
  //       setPermission(true);
  //     } else {
  //       setPermission(false);
  //     }
  //     dispatch(getCampusesAction(campuses.data));
  //   });
  // }, []);

  const renderContent = routes => {
    let result = null;

    if (routes.length > 0) {
      result = routes.map(route => {
        return route.isPrivate ? (
          <PrivateRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
            layout={route.layout}
          />
        ) : (
          <PublicRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      });
    }

    return (
      <Switch>
        {permission ? (
          result
        ) : (
          <h3>You not have permission to access this page!</h3>
        )}
      </Switch>
    );
  };

  return (
    <BrowserRouter history={history}>{renderContent(routes)}</BrowserRouter>
  );
}

export default App;
