import { configureStore } from '@reduxjs/toolkit';
import initDataReducer from '../Reducers/initDataReducer';

const rootReducer = {
  initDataReducer,
};
const store = configureStore({
  reducer: rootReducer,
});

export default store;
