import React from 'react';

import _ from 'lodash';
import moment from 'moment';

export * from './cookie';
export * from './createReducer';

export const objFromArray = array => {
  const newObj = {};
  array?.forEach(element => {
    newObj[element.id] = element.name;
  });
  return newObj;
};

// auto add object { value: '', label: 'All' } on the first element in array option
export const mapOptions = options => {
  // input: [{value: "GH01", label: "GH Grade 01"} ,...]
  const newOptions = options.map(i => ({
    value: i.id,
    label: i.name,
  }));

  newOptions.unshift({ value: '', label: 'All' });
  return newOptions;
  // output: [{ value: '', label: 'All' }, {value: "GH01", label: "GH Grade 01"} , ...]
};

export const mapFormOptions = options => {
  return options.map(i => ({
    value: i.id,
    label: i.name,
  }));
};

// muc dich kiem tra de truyen params khong bi loi
export const removeEmpty = obj => {
  // {schoolYearId: 2020, termId: 118}
  const newObj = { ...obj };
  // neu schoolYearId va termId === '' thi xoa 2 key do => return obj rong
  Object.keys(newObj).forEach(key => newObj[key] === '' && delete newObj[key]);
  return newObj;
};

export const getTimeRange = (lessons, duration, currentTab) => {
  const currentLessons = _.filter(
    lessons,
    l => moment(l).day() === Number(currentTab)
  );
  return currentLessons.map(lesson => {
    const endMoment = moment(lesson).subtract(7, 'hours');
    const startMoment = moment(lesson).subtract(7, 'hours');
    const start = startMoment.subtract(duration, 'minutes').format('hh:mm A');
    const end = endMoment.format('hh:mm A');
    return `${start} - ${end} `;
  });
};

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const convertBufferBase64 = (data, cb) => {
  const arrayBufferView = new Uint8Array(data);
  const blob = new Blob([arrayBufferView], { type: 'image/png' });
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};

export const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const urlify = text => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const textReplace = text.replace(
    urlRegex,
    url =>
      `<a target="_blank" rel="noopener noreferrer" href="${url}">${url}</a>`
  );
  return <span dangerouslySetInnerHTML={{ __html: textReplace }} />;
};

export const mapParams = (params, array, keyString) => {
  let newParams = {};
  if (array && array.length > 0) {
    const parameters = _.omit(params, [keyString]);
    newParams = new URLSearchParams({ ...parameters });
    array.forEach(x => {
      newParams.append(keyString, x);
    });
  } else {
    newParams = params;
  }
  return newParams;
};
