import { fetchApi, fetchToken, uploadFile } from 'Utils';
// endPoint / method / body / params / sourceToken
export const getCampuses = () => fetchApi('campuses', 'GET');
export const getSchoolYear = () => fetchApi('school-years', 'GET');
export const getTerms = () => fetchApi('terms', 'GET');
export const getPeriods = () => fetchApi('asa-periods', 'GET');
export const getYearGroups = () => fetchApi('yearGroups', 'GET');

export const getClasses = params => fetchApi('asa-classes', 'GET', {}, params);
export const setClasses = payload => fetchApi('asa-classes', 'POST', payload);
export const getClassDetails = id => fetchApi(`asa-classes/${id}`, 'GET');
export const setClassDetails = (id, payload) =>
  fetchApi(`asa-classes/${id}`, 'PATCH', payload);
export const getStudentsClass = id =>
  fetchApi(`asa-classes/${id}/students`, 'GET');
export const deleteStudentsClass = (classId, studentId) =>
  fetchApi(`asa-classes/${classId}/students/${studentId}`, 'DELETE');
export const uploadClasses = file =>
  uploadFile('asa-classes/import', 'POST', file);

export const getGradeClasses = () => fetchApi('gradeClasses', 'GET');

export const getStudents = params => fetchApi('students', 'GET', {}, params);
export const getRequiredClassChildren = params =>
  fetchApi('asa-required-class-children', 'GET', {}, params);
export const getStudentSchedule = params =>
  fetchApi('asa-student-schedule', 'GET', {}, params);

export const getBookings = params =>
  fetchApi('asa-bookings', 'GET', {}, params);
export const getBookingDetail = id => fetchApi(`asa-bookings/${id}`, 'GET');
export const setBookingDetailById = (id, body) =>
  fetchApi(`asa-bookings/${id}`, 'PATCH', body);
export const setBookingDetail = payload =>
  fetchApi('asa-bookings', 'POST', payload);
export const setBookingPayment = (id, payload) =>
  fetchApi(`asa-bookings/${id}/payments`, 'POST', payload);

export const getEmailTemplate = () => fetchApi('email-template', 'GET');
export const setEmailTemplate = payload =>
  fetchApi(`email-template/${payload.id}`, 'PATCH', payload);
export const sendMailAllocation = payload =>
  fetchApi('email-allocation', 'POST', payload);

export const getAttendances = params =>
  fetchApi('asa-attendances', 'GET', {}, params);
export const checkAttendance = payload =>
  fetchApi('asa-check-attendance', 'POST', payload);

export const getClassesByStudentId = id =>
  fetchApi(`asa-students/${id}/classes`, 'GET');
export const postReservations = (id, payload) =>
  fetchApi(`asa-students/${id}/reservations`, 'POST', payload);
export const deleteReservations = (studentId, classId) =>
  fetchApi(`asa-students/${studentId}/reservations/${classId}`, 'DELETE');
export const setTransactionById = (id, payload) =>
  fetchApi(`asa-transaction/${id}`, 'PATCH', payload);
export const setTransactionByBookingId = (id, payload) =>
  fetchApi(`asa-bookings/${id}/transactions`, 'POST', payload);

export const uploadImage = file => uploadFile('files', 'POST', file);
export const getSession = () => fetchApi('session', 'GET');
export const getChildren = () => fetchApi('asa-children', 'GET');

export const getDataUser = () => fetchApi('permissions/info', 'GET');
export const getImage = params => fetchApi(`files/${params}`, 'GET');
export const trackingError = payload =>
  fetchApi(`tracking-error`, 'POST', payload);
export const getRefreshToken = () => fetchToken('get-refresh-token', 'GET');
export const postRefreshToken = payload =>
fetchApi('refresh-token', 'POST', payload);

export * from './ConversationApi';
export * from './DashboardApi';
export * from './ReportApi';
