import { Modal } from 'antd';
import { postRefreshToken } from 'Api';
import axios from 'axios';
import {
  API_URL,
  AUTH_URL,
  REFRESH_TOKEN,
  TOKEN_KEY,
  TOKEN_TRACKING_ERROR,
  VERSION_TRACKING_ERROR,
} from 'GlobalConstants';
import { getCookie } from './Helpers';

const instance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

instance.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (!config.headers.Authorization) {
      // const token = JSON.parse(localStorage.getItem(TOKEN_KEY));
      const token = getCookie(TOKEN_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        // config.headers.Cookie = `jwt=${token}`;
      }
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // token expiry
    const expireStatus = [401, 403];
    if (
      error.response &&
      !expireStatus.includes(error.response.status) &&
      error.response.config.url !== '/tracking-error'
    ) {
      const userId = JSON.parse(localStorage.getItem('userId'));
      const payload = {
        version: VERSION_TRACKING_ERROR,
        statusCode: error.response.status,
        userId: userId || '-1',
        message: error?.response?.data?.message || error?.message,
        deviceInfo: navigator.userAgent,
        path: window.location.href,
        serviceName: 'Chat History',
        level: 'Error',
        token: TOKEN_TRACKING_ERROR,
      };
      fetchApi('/tracking-error', 'POST', payload);
    }
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            })
            .catch(error => {
              return Promise.reject(error);
            });
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = JSON.parse(localStorage.getItem(REFRESH_TOKEN));
      return new Promise((resolve, reject) => {
        postRefreshToken({ refreshToken })
          .then(res => {
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.setItem(
              REFRESH_TOKEN,
              JSON.stringify(res.data.refreshToken)
            );
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.accessToken;
            originalRequest.headers['Authorization'] =
              'Bearer ' + res.data.accessToken;
            processQueue(null, res.data.accessToken);
            resolve(axios(originalRequest));
          })
          .catch(() => {
            processQueue(error, null);
            reject(error);
            setTimeout(() => {
              Modal.warning({
                title: 'Token expiry time',
                content: 'Please login again',
                keyboard: false,
                onOk: () => {
                  window.location.href = AUTH_URL;
                },
              });
            }, 1000);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    if (error.response) {
      return Promise.reject(error.response);
    }
    if (error.request) {
      return Promise.reject(error.request);
    }
    return Promise.reject(error.message);
  }
);

export async function fetchApi(
  endPoint,
  method = 'GET',
  body,
  params = {},
  sourceToken = null
) {
  return instance({
    url: endPoint,
    method: method,
    data: body,
    params: params,
    sourceToken: sourceToken,
  });
}

export async function fetchToken(
  endPoint,
  method = 'GET',
  body,
  params = {},
  sourceToken = null
) {
  return instance({
    url: endPoint,
    method: method,
    data: body,
    params: params,
    sourceToken: sourceToken,
    withCredentials: true,
  });
}

export async function uploadFile(
  endpoint,
  method = 'POST',
  body,
  params = {},
  sourceToken = null
) {
  return instance({
    method: method,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: endpoint,
    data: body,
    params: params,
    cancelToken: sourceToken,
  });
}

export async function downloadFile(
  endpoint,
  method = 'POST',
  body,
  params = {},
  sourceToken = null
) {
  return instance({
    method: method,
    url: endpoint,
    data: body,
    params: params,
    cancelToken: sourceToken,
    responseType: 'arraybuffer',
  });
}

export async function fetchAllApi(requests = []) {
  return axios.all(requests);
}
