import { fetchApi } from 'Utils';

export const getConversation = params => {
  return fetchApi('/report/conversation', 'GET', {}, params);
};

export const getConversationDetail = params => {
  return fetchApi('/report/conversation/detail', 'GET', {}, params);
};

export const getConversationMonitorAvatar = params => {
  return fetchApi(`/report/conversation/teacherAvatar/${params}`, 'GET');
};

export const getConversationParentAvatar = params => {
  return fetchApi(`/report/conversation/parentAvatar/${params}`, 'GET');
};
