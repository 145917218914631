import { Layout, Menu, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getDataUser } from 'Api';
import Logo from 'Assets/Images/logo.png';
import { CAMPUS_INFO } from 'GlobalConstants';
import React, { Suspense, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './_styles.scss';
const { Header, Content, Footer } = Layout;

export function ParentLayout(props) {
  const [userName, setUserName] = useState('');
  const [status, setStatus] = useState(true);

  const handleRedirect = () => {
    window.location.href = 'http://services.vas.dev.nascorp.vn/parents';
  };

  const handleReplaceUsername = name => 'Hi, ' + name.split('@')[0];

  const handleLogout = () => {
    window.location.href = 'http://services.vas.dev.nascorp.vn/';
  };

  useEffect(() => {
    getDataUser().then(res => {
      if (res.status === 200 && res.data) {
        setUserName(res.data.username);
      }
    });
  }, []);

  useEffect(() => {
    const changeStatus = () => {
      setStatus(navigator.onLine);
    };
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, [status]);

  return (
    <Spin
      tip="Network connection error..."
      spinning={!status}
      indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}
    >
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="parent-header">
          <div className="top-bar-left">
            <img
              className="parent-logo"
              src={Logo}
              onClick={handleRedirect}
              alt="Vietnam Australia international school"
            />
          </div>
          <div className="top-bar-right">
            <div className="menu-bar">
              <NavLink to="/booking/students" className="menu-bar-link">
                BOOKING CLASSES
              </NavLink>
              {/* <NavLink to="/parent/attendance" className="menu-bar-link">
                ATTENDANCE
              </NavLink> */}
              <NavLink to="/schedule/list" className="menu-bar-link">
                SCHEDULE
              </NavLink>
            </div>

            <Menu
              className="user-profile"
              mode="horizontal"
              triggerSubMenuAction="click"
            >
              <Menu.SubMenu
                title={handleReplaceUsername(userName)}
                key="sub-menu-profile"
              >
                <Menu.Item key="change-password">Change password</Menu.Item>
                <Menu.Item key="profile">Profile</Menu.Item>
                <Menu.Item key="logout" onClick={handleLogout}>
                  Logout
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </div>
        </Header>

        <Content>
          <Suspense
            fallback={
              <Spin>
                <div className="is-spining" />
              </Spin>
            }
          >
            {props.children}
          </Suspense>
        </Content>

        <Footer className="parent-footer">
          <div className="campus-info-list">
            {CAMPUS_INFO.map(item => (
              <div key={item.id} className="column">
                <h6 className="campus-title">{item.name}</h6>
                <div
                  className="campus-text"
                  dangerouslySetInnerHTML={{ __html: item.info }}
                ></div>
              </div>
            ))}
          </div>
          <div className="bottom-bar">
            <div className="copyright">
              <span>
                Our website is best viewed using Chrome, Firefox, Safari.
              </span>
            </div>
          </div>
        </Footer>
      </Layout>
    </Spin>
  );
}
