import React from 'react';

import { AdminLayout } from 'Layouts';
// import { AdminLayout, ParentLayout } from 'Layouts';

const AdminPage = React.lazy(() => import('./Pages/AdminPage/AdminPage'));
const HomePage = React.lazy(() => import('./Pages/HomePage'));
const DashboardPage = React.lazy(() => import('./Pages/DashboardPage/DashboardPage'));
const ConversationsPage = React.lazy(() =>
  import('./Pages/ConversationsPage/ConversationsPage')
);
const ReportPage = React.lazy(() => import('./Pages/ReportPage/ReportPage'));

const routes = [
  {
    path: '/',
    exact: true,
    layout: AdminLayout,
    component: HomePage,
    isPrivate: true,
  },
  {
    path: '/history/admin',
    exact: true,
    layout: AdminLayout,
    component: AdminPage,
    isPrivate: true,
  },
  {
    path: '/history/admin/dashboard',
    exact: true,
    layout: AdminLayout,
    component: DashboardPage,
    isPrivate: true,
  },
  {
    path: '/history/admin/conversations',
    exact: true,
    layout: AdminLayout,
    component: ConversationsPage,
    isPrivate: true,
  },
  {
    path: '/history/admin/report',
    exact: true,
    layout: AdminLayout,
    component: ReportPage,
    isPrivate: true,
  },
];

export default routes;
