import React from 'react';
import { Route } from 'react-router-dom';

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest} // key, path, exact, component, layout
      render={props => {
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};
