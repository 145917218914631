export const DAYS_OF_WEEK = [
  { value: '', label: 'All' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];
export const SCHOOLS = [
  { value: '', label: 'All' },
  { value: 'primary', label: 'Primary' },
  { value: 'second', label: 'Secondary' },
];
export const STATUS = [
  { value: '', label: 'All' },
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
  { value: 'finished', label: 'Finished' },
];
export const STUDENT_STATUS = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
export const CAMPUS_INFO = [
  {
    id: 1,
    name: 'Sunrise',
    info: '1 Đường số 20, Khu Him Lam, Tân Hưng | Quận 7 <br>HCMC |Điện thoại: (028) 6260 7272<br><br>',
  },
  {
    id: 2,
    name: 'Hoàng Văn Thụ',
    info: '202 Hoàng Văn Thụ | Quận Phú Nhuận <br>HCMC |Điện thoại: (028) 3999 0112<br><br>',
  },
  {
    id: 3,
    name: 'Phan Xích Long',
    info: '1L Phan Xích Long | Quận Bình Thạnh <br>HCMC | Điện thoại: (028) 3517 2747/49<br><br>',
  },
  {
    id: 4,
    name: 'Ba Tháng Hai',
    info: '594 Ba Tháng Hai, Phường 14 | Quận 10 <br>HCMC | Điện thoại: (028) 3864 1770/72<br><br>',
  },
  {
    id: 5,
    name: 'Lê Quý Đôn',
    info: '32 Lê Quý Đôn | District 7 <br>HCMC | Điện thoại: (028) 3932 2849/50<br><br>',
  },
  {
    id: 6,
    name: 'Garden Hills',
    info: '168 Phan Văn Trị, P.5, Q.Gò Vấp <br>HCMC | Điện thoại: (028) 3588 3088<br><br>',
  },
  {
    id: 7,
    name: 'Riverside',
    info: '99 Nguyễn Thị Thập, P. Tân Phú, Quận 7 <br>HCMC |Điện thoại: (028) 3622.0898<br><br>',
  },
  {
    id: 8,
    name: 'Sala',
    info: '10 Mai Chí Thọ, Quận 2 <br>HCMC | Điện thoại: (028) 3622 0833<br><br>',
  },
];
