import { Col, Image, Row, Typography } from 'antd';
import { AUTH_URL } from 'GlobalConstants';
import React from 'react';
import { useHistory } from 'react-router';
import logo from '../../Assets/Images/logo.png';
import './_header.scss';

const Header = () => {
  const history = useHistory();
  const handleLogout = () => {
    window.location.href = AUTH_URL;
  };

  return (
    <div className="header">
      <Row align="middle" justify="start">
        <Col flex="360px" style={{ backgroundColor: '#fff' }}>
          <Image
            src={logo}
            width={80}
            preview={false}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/')}
          />
        </Col>
        <Col
          flex="auto"
          style={{ justifyContent: 'space-between', padding: '0 24px' }}
        >
          <Typography.Title level={3}>Chat History Management</Typography.Title>
          <Typography.Title
            onClick={handleLogout}
            style={{ cursor: 'pointer', fontSize: '15px', margin: 0 }}
          >
            Logout
          </Typography.Title>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
