import { Button, Result } from 'antd';
import { trackingError } from 'Api';
import { TOKEN_TRACKING_ERROR, VERSION_TRACKING_ERROR } from 'GlobalConstants';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    const userId = JSON.parse(localStorage.getItem('userId'));
    const deviceInfo = navigator.userAgent;

    const payload = {
      version: VERSION_TRACKING_ERROR || '',
      statusCode: 0,
      userId: userId || '-1',
      message: error.message || '',
      deviceInfo: deviceInfo,
      path: window.location.href,
      serviceName: 'Chat History',
      level: 'Error',
      token: TOKEN_TRACKING_ERROR || 'tokentrackingerror',
    };
    trackingError(payload);
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   console.log('error :>> ', error);
  //   console.log('errorInfo :>> ', errorInfo);
  // }

  navigate = () => {
    window.location.href = `${window.location.href}`;
  };

  render() {
    // fallback component
    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="Something went wrong"
          subTitle="Please try again."
          extra={
            <Button className="btn btn-primary" onClick={this.navigate}>
              Back
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
