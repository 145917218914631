import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Spin } from 'antd';
import Header from 'Components/Header';
import SideBar from 'Components/SideBar';
import React, { Suspense, useEffect, useState } from 'react';

const { Content } = Layout;

export const AdminLayout = props => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    const changeStatus = () => {
      setStatus(navigator.onLine);
    };
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, [status]);

  return (
    <Spin
      tip="Network connection error..."
      spinning={!status}
      indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}
    >
      <Layout>
        <Header title={props.location.state} />
        <Layout className="app-layout">
          <SideBar defaultSelected={props.location.state} />
          <Content>
            <Suspense
              fallback={
                <Spin style={{ height: 'calc(100vh - 150px)' }}>
                  <div className="is-spining" />
                </Spin>
              }
            >
              <div className="main-content">{props.children}</div>
            </Suspense>
          </Content>
        </Layout>
        {/* <Footer style={{ textAlign: 'center', backgroundColor: '#fff' }} /> */}
      </Layout>
    </Spin>
  );
};
