import {
  faChartLine,
  faComments,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './_sideBar.scss';
const { Sider } = Layout;

const menuItems = [
  {
    path: '/history/admin/dashboard',
    key: 'dashboard',
    iconComponent: <FontAwesomeIcon icon={faChartLine} />,
    label: 'Dashboard',
  },
  {
    path: '/history/admin/conversations',
    key: 'conversations',
    iconComponent: <FontAwesomeIcon icon={faComments} />,
    label: 'Conversations',
  },
  {
    path: '/history/admin/report',
    key: 'report',
    iconComponent: <FontAwesomeIcon icon={faFile} />,
    label: 'Report',
  },
];

const Sidebar = ({ defaultSelected }) => {
  const history = useHistory();
  const handleClickItem = (path, key) => {
    history.push(path, key);
  };

  return (
    <Sider
      className="side-bar"
      width={360}
      height={100}
      collapsedWidth={80}
      breakpoint="xl"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[defaultSelected || 'dashboard']}
        style={{ height: '100%', borderRight: 0 }}
      >
        {menuItems.map(item => (
          <Menu.Item
            onClick={() => handleClickItem(item.path, item.key)}
            className="menu-item"
            key={item.key}
            disabled={item.disabled}
          >
            <p>{item.label}</p>
            {item.iconComponent}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
