import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboard: [],
  conversation: [],
  report: [],
  campuses: [],
};

const actionMap = {
  getDashboardAction: (state, action) => {
    state.dashboard = action.payload;
  },
  getConversationAction: (state, action) => {
    state.conversation = action.payload;
  },
  getReportAction: (state, action) => {
    state.report = action.payload;
  },
  getCampusesAction: (state, action) => {
    state.campuses = action.payload;
  },
};

// initDataSlice
const initDataReducer = createSlice({
  name: 'initData',
  initialState: initialState,
  reducers: actionMap,
});

const { actions, reducer } = initDataReducer;
export const {
  getDashboardAction,
  getConversationAction,
  getReportAction,
  getCampusesAction,
} = actions;
export default reducer;
